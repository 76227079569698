//$body-background: rgb(24, 24, 24);
$body-background: rgb(0, 0, 0);
$color-text: rgb(255, 255, 255);
$color-primary: #B70000;
$color-red: #B70000;
$color-red-alt: #cc0000;
$color-primary-dark: rgba(98, 12, 6, 100%);
$color-yellow: #F4CD25;
$color-gray: #939393;

$color-text-reverse: #222;

$hero-size: 3.7rem;
$body-size: 2rem;
$body-size-small: 1rem;
$body-size-micro: 0.65rem;

$mobile-icon-size: 1.8rem;
$header-height: 5.2rem;