@mixin animateme($name, $duration, $delay, $count, $fill) {

  // animation-name
  // animation-duration
  // animation-timing-function
  // animation-delay
  // animation-direction
  // animation-iteration-count
  // animation-fill-mode
  // animation-play-state

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;

  -webkit-animation: $name $duration ease-in-out $delay normal $count $fill;
  /* Chr, Saf */
  animation: $name $duration ease-in-out $delay normal $count $fill;
  /* IE >9, Fx >15, Op >12.0 */
}

// GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE GLIDE

@-webkit-keyframes glide {
  25% {
    width: 29rem;
    left: 0;
  }

  50% {
    width: 0;
    left: 29rem;
  }
}

@keyframes glide {
  25% {
    width: 29rem;
    left: 0;
  }

  50% {
    width: 0;
    left: 29rem;
  }
}

// APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR APPEAR

@-webkit-keyframes appear {
  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1);
  }
}

@keyframes appear {
  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

// PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE PULSATE

@-webkit-keyframes pulsate {
  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulsate {
  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

// RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE RADIATE

$max-radius: 25px;
$med-radius: 15px;
$min-radius: 3px;

@-webkit-keyframes radiate {
  2% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  4% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  6% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  16% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  20% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  25% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  25% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  50% {
    box-shadow: 0px 0px $max-radius $color-primary;
  }

  100% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }
}

@keyframes radiate {
  4% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  8% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  12% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  16% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  20% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  25% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  25% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  50% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  100% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }
}

// FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER FLICKER

$max-radius: 25px;
$med-radius: 15px;
$min-radius: 3px;

@-webkit-keyframes flicker {
  2% {
    box-shadow: 0px 0px $min-radius $color-primary;
    opacity: 0.1;
  }

  4% {
    box-shadow: 0px 0px $med-radius $color-primary;
    opacity: 1;
  }

  6% {
    box-shadow: 0px 0px $min-radius $color-primary;
    opacity: 0.1;
  }

  16% {
    box-shadow: 0px 0px $min-radius $color-primary;
    opacity: 1;
  }

  20% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  25% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  28% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  50% {
    box-shadow: 0px 0px $max-radius $color-primary;
  }

  100% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }
}

@keyframes flicker {
  2% {
    box-shadow: 0px 0px $min-radius $color-primary;
    opacity: 0.1;
  }

  4% {
    box-shadow: 0px 0px $med-radius $color-primary;
    opacity: 1;
  }

  6% {
    box-shadow: 0px 0px $min-radius $color-primary;
    opacity: 0.1;
  }

  16% {
    box-shadow: 0px 0px $min-radius $color-primary;
    opacity: 1;
  }

  20% {
    box-shadow: 0px 0px $med-radius $color-primary;
  }

  25% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  28% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }

  50% {
    box-shadow: 0px 0px $max-radius $color-primary;
  }

  100% {
    box-shadow: 0px 0px $min-radius $color-primary;
  }
}

//
// @-webkit-keyframes glide{
//   25%{width: 29rem; left: 0;}
//   50%{ width: 0; left: 29rem;}
// }
// @keyframes glide{
//   25%{width: 29rem; left: 0;}
//   50%{ width: 0; left: 29rem;}
// }
//
// @-webkit-keyframes glide2{
//   25%{width: 12rem; left: 0;}
//   50%{ width: 0; left: 12rem;}
// }
// @keyframes glide2{
//   25%{width: 12rem; left: 0;}
//   50%{ width: 0; left: 12rem;}
// }
//
// @-webkit-keyframes glide_mobile{
//   25%{width: 17rem; left: 0;}
//   50%{ width: 0; left: 17rem;}
// }
// @keyframes glide_mobile{
//   25%{width: 17rem; left: 0;}
//   50%{ width: 0; left: 17rem;}
// }