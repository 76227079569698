@import "styles/partials/reset";
@import "styles/partials/grid";
@import "styles/partials/fonts";
@import "styles/partials/variables";
@import "styles/partials/animations";

* {
  outline: none;
  white-space: pre-line;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Ferrari-Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;

  background-color: $body-background;
  color: $color-text;
}

.language_zh {
  font-family: "SimHei", sans-serif;

  .author {
    font-family: "Ferrari-Sans", sans-serif;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
}

h2 {
  font-size: 1.8rem;
  font-weight: 400;
}

// HOME --------------------------------------------------------
.app-wrapper {
  background-image: url("./img/back_top_left.png"),
    url("./img/back_top_right.png"), url("./img/back_top_center.png");

  background-position: top left, top right, top center;

  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: 35%, 35%, 35%; //ok a 1920x1080
  background-attachment: fixed, fixed, fixed;
}

.video-background {
  object-fit: cover;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.page-header {
  margin: 1rem 0;
}

.page-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem; //ok per video, meno per contenuto normale
}

.page-footer {
  overflow: visible;
  z-index: 500;

  .restartContainer {
    .box {
      //width: 20rem;

      .button {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

video {
  object-fit: cover;
  // width: 100vw;
  // height: 100vh;
  // position: fixed;
  // top: 0;
  // left: 0;
}

.videoOverlay {
  @include transitionall(0.2s);
  //@include transitionallcubic(0.4s);

  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75%);
  width: 100%;
  min-height: 100vh;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }
}

.box {
  &.removepadding {
    padding: 0;
  }
}

.videoContainer {
  $icon-size: 2.5rem;

  width: 100%;
  outline: none;
  padding: 4rem;

  position: relative;
  overflow: visible;

  .buttonCloseVideo {
    position: absolute;
    top: $icon-size - $icon-size/3.5;
    right: $icon-size - $icon-size/3.5;
    z-index: 500;

    font-size: 0.8rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;

    border: 0;
    background: transparent;
    min-height: 3rem;

    cursor: pointer;
    flex-grow: 0;
    display: flex;
    padding: 1rem;
    text-align: center;
    justify-content: center;
    margin: 0;

    color: $color-text;

    img {
      width: $icon-size;
      display: inline-block;
    }
  }
}

.buttonsContainer {
  padding: 1rem 0;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.buttonsInfo {
  color: $color-text;
  font-style: italic;
  padding: 1rem 0;
  font-weight: 600;
  line-height: 1.2rem;
  margin-bottom: 1rem;
}

.videoSelectorWrapper {
  margin-top: -10%;
}

.box.col-12.videoSelector {
  padding: 1rem 0;
  flex-basis: 85%;
}

.box.col-12.videoNavigator {
  margin-top: 4rem;
  flex-basis: 85%;
  padding: 0;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper>div {
  position: absolute;
}

.wrapper .container.modalContainer {
  width: 60%;
}

.modalMessage {
  color: $color-gray;
  font-style: italic;
  padding: 1rem 0;
  font-weight: 600;
}

.icon-check {
  display: flex;
  padding-left: 1rem;

  img {
    width: 1.8rem;
    display: inline-block;
  }
}

.icon-placeholder {
  width: 2.8rem;
}

.controlButtonsContainer {
  padding: 1rem 0;
  flex-direction: row;
}

.button {
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-transform: uppercase;

  border-radius: 5rem;
  border: 2px solid white;
  min-height: 3.5rem;

  cursor: pointer;
  flex-grow: 1;
  display: flex;
  padding: 0.8125rem 1rem 1rem 2.2rem;
  text-align: center;
  justify-content: center;
  margin: 1rem 0;

  background-color: rgba(0, 0, 0, 40%);
  color: $color-text;
  font-weight: 600;

  @include transitionall(0.2s);
  //@include transitionallcubic(0.4s);

  &.ghost {
    border: 0;
    color: $color-text;
    padding-left: 0;
    z-index: 500;
    background-color: transparent;

    &:hover {
      color: $color-text;
    }

    .icon {
      img {
        margin-left: 0.5rem;
      }
    }
  }

  .info {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2.2rem;

    .title {
      font-weight: 600;
    }

    .author {
      text-transform: capitalize;
      font-size: 0.8rem;
      padding-top: 0.3rem;
      font-weight: 300;
    }
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 2.2rem;
      display: inline-block;
      //margin-left: .5rem;
    }
  }

  &.red {
    border-color: red;

    &:hover {
      background-color: red;
    }
  }

  &.white {
    border-color: white;

    &:hover {
      background-color: white;
      color: $color-text-reverse;
    }
  }

  &.yellow {
    border-color: $color-yellow;

    &:hover {
      background-color: $color-yellow;
      color: $color-text-reverse;
    }
  }

  &.selector {
    border-color: $color-red;
    color: $color-text;
    padding-left: 2.2rem;

    &:hover {
      background-color: $color-red;
      color: white;
    }
  }

  &.navigator {
    border-color: white;
    color: $color-red;
    padding-left: 2.2rem;

    &:hover {
      background-color: $color-red;
      color: white;
    }
  }

  &.finale {
    padding: 1rem;
  }
  
  &.confirm {
    border-color: white;
    color: white;
    background-color: $color-red;

    &:hover {
      border-color: $color-red;
      color: white;
      background-color: $color-red;
    }
  }

  &.play {
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-text;
    }
  }

  &.full {
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: $color-text-reverse;
    }
  }

  //Hover generale
  &:hover {
    background-color: $color-primary;
    border-color: $color-primary;

    @include animateme(radiate, 2.5s, 0s, infinite, forwards);
  }
}

// .loader-logo-wrapper {
//   margin: 0;
//   width: 100%;
//   height: calc(100vh - 3rem);
// }

// Loader ------------------------------- //
.loader-logo {
  height: 4rem;
  @include animateme(pulsate, 2s, 0s, infinite, forwards);
}

.loader-logo-container {
  margin: 0;
  width: 100%;
  height: calc(100vh - 3rem);
}

// End Loader --------------------------  //
// Header ------------------------------- //

.header-home {
  .wrapper .container & {
    width: 90%;
    max-width: 90em;
  }

  &>div {
    padding: 1rem 0;
  }

  .header-center {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.header {
  height: $header-height;

  &>div.box {
    padding: 0;
  }

  .headline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-primary;
    text-transform: uppercase;
    font-weight: 400;
  }

  // .logo {
  //   height: 4rem;
  //   border: 1px solid red;
  // }

  .header-logo {
    width: 30px;
  }

  .header-logo-alt {
    display: block;
    width: 100%;
    max-width: 350px;
  }
}

// End Header --------------------------- //
// Pre Reveal --------------------------- //

.reveal-container {
  width: 100%;
  height: calc(100vh);
}

.main-title {
  color: #ff0000;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.99rem;
  word-spacing: -8%;
  margin-bottom: 2.8rem;
}

.sub-title {
  color: $color-text;
  text-transform: uppercase;
  font-weight: 400;
  padding: 2rem 0 1rem;
  letter-spacing: 0.33rem;
  word-spacing: -20%;
  font-size: 1.625rem;

  padding: 0;
  margin-bottom: 6.5rem;

  &.red {
    color: $color-primary;
  }
}

.old-browser {
  color: $color-text;
  padding: 0 7rem 0 7rem;
  font-size: 1.2rem;
  line-height: 2.2rem;
}

.breaker {
  display: none;
}

.rotate-message {
  color: $color-text;
  font-style: italic;
  font-weight: 200;
  font-size: 14px;
  padding: 1rem 0 0 0;
  margin-top: 1rem;
  display: none;
}

.audio-info {
  color: $color-text;
  font-style: italic;
  font-weight: 400;
  line-height: 1.3rem;

  img.audio_icon {
    width: 2.3rem;
    margin-right: 1rem;
    margin-bottom: -0.5rem;
  }
}

.box.col-4.start-button {
  .button {
    padding: 1rem;
  }
}

.reveal-date {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.4rem;
  margin-top: 6rem;
  position: relative;

  div {
    display: inline-block;
    margin: 0 0.5rem;
  }

  .date-sep {
    color: $color-primary;
    background-color: $color-primary;
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    position: relative;
    top: 1rem;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// End Pre Reveal --------------------------------------------------- //
.language-switcher {
  //height: 3rem;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.language-selector-wrapper {
  width: 5rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.home {
    height: $header-height;
  }

  .language-selector {
    border: 0;
    width: 8rem;
    color: $color-text;
    background-color: transparent;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }

  &.is-open {
    .language-selector-arrow {
      right: 40px;
      border-color: transparent transparent $color-text;
    }
  }

  .language-selector-arrow {
    right: 40px;
    border-color: $color-text transparent transparent;
  }

  .language-items {
    // width: 4.5rem;
    max-height: 260px;
    margin-top: -20px;
    background-color: rgba(0, 0, 0, 0.8);
    color: $color-text;
    border: 0;
    border-top: 2px solid $color-text;

    &.div {
      color: $color-text;
    }

    .Dropdown-option {
      color: $color-text;
      font-weight: bold;

      &:hover {
        color: $body-background;
        background-color: rgba(255, 255, 255, 1);
      }

      &.is-selected {
        color: $color-red-alt;
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }

  //Per quando in home è posizionato in basso
  &.bottom {
    .language-items {
      top: -55%;
      border-top: 0;
      border-bottom: 2px solid $color-text;
    }
  }
}

///___ ALERT OLD BROWSER_______________
body {
  .buorg {
    position: absolute;
    position: fixed;
    z-index: 111111;
    width: 100%;
    top: 0px;
    left: 0px;
    border-bottom: 1px solid $color-primary;
    text-align: center;
    color: $color-text;
    background-color: rgba(0, 0, 0, 0.75);
    font: 18px Calibri, Helvetica, sans-serif;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    animation: 1s ease-out 0s buorgfly;

    font-size: 0.8rem;
    font-family: "Ferrari-Sans", sans-serif;
    font-weight: 300;
  }

  .buorg-pad {
    padding: 9px;
    line-height: 1.7em;
  }

  .buorg-buttons {
    display: block;
    text-align: center;
    margin: 1rem 0;
  }

  #buorgig,
  #buorgul,
  #buorgpermanent {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 1rem;
    font-weight: normal;
    white-space: nowrap;
    margin: 0 2px;
    display: inline-block;
    border-radius: 5rem;
    border: 1px solid white;

    cursor: pointer;

    background-color: rgba(0, 0, 0, 40%);
    color: $color-text;
    font-weight: 300;
  }

  #buorgig {
    background-color: $color-primary;
  }

  @media only screen and (max-width: 700px) {
    .buorg div {
      padding: 5px 12px 5px 9px;
      line-height: 1.3em;
    }
  }

  @keyframes buorgfly {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
  }

  .buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
    display: inline-block;
    background: no-repeat 0px center;
  }
}

// MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES
// MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES
// MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES
// MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES MEDIA QUERIES

@import "styles/partials/mediaqueries";