@media only screen and (max-width: 1550px) {
  .main-title {
    $title-size: 4.5rem;
    font-size: $title-size;
    letter-spacing: $title-size/5;
    word-spacing: -8%;
    margin-bottom: 1.2rem;
  }
}

@media only screen and (max-width: 1420px) {
  .button {

    &.selector,
    &.navigator {
      padding-left: 0.5rem;
    }
  }
}

@media only screen and (max-width: 1380px) {
  .main-title {
    font-size: 3.3rem;
    margin-bottom: 1.4rem;
  }

  .sub-title {
    font-size: 1.3rem;
    padding: 0;
    margin-bottom: 3.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .main-title {
    $title-size: 3rem;
    font-size: $title-size;
    letter-spacing: $title-size/5;
    word-spacing: -8%;
    margin-bottom: 1.2rem;
  }

  .button {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.5rem 0.6rem;
    margin: 1rem 0;

    .info {
      min-height: 0;

      .title {
        font-weight: 600;
      }

      .author {
        text-transform: capitalize;
        font-size: 0.6rem;
        font-weight: 300;
      }
    }

    .icon {
      img {
        width: 1.8rem;
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .videoSelector {
    .col-4 {
      flex-basis: 100%;
      padding: 0;
    }
  }

  .videoNavigator {
    .col-4 {
      flex-basis: 100%;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .main-title {
    $title-size: 2.5rem;
    font-size: $title-size;
    letter-spacing: $title-size/5;
    word-spacing: -8%;
    margin-bottom: 1.2rem;
  }
}

@media (orientation: portrait) and (max-width: 900px) {
  .rotate-message {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .language-items {
    margin-top: -8px !important;
  }

  .header {

    .col-6,
    .col-3 {
      flex-basis: 50%;
    }
  }

  .header-home {
    .header-left {
      flex-basis: 25%;
    }

    .header-center {
      flex-basis: 50%;
    }

    .header-right {
      flex-basis: 25%;
    }
  }
}

@media only screen and (max-width: 780px) {
  .main-title {
    $title-size: 2rem;
    font-size: $title-size;
    letter-spacing: $title-size/5;
    word-spacing: -8%;
    margin-bottom: 1.2rem;
  }

  .sub-title {
    font-size: 1.625rem;
    letter-spacing: 0.33rem;
    word-spacing: -20%;
  }

  .breaker {
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .language-items {
    margin-top: -8px;
  }

  .wrapper .container.modalContainer {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .header-home {
    .wrapper .container & {
      width: 100%;
    }

    .header-left {
      flex-basis: 33%;
    }

    .header-center {
      flex-basis: 33%;
    }

    .header-right {
      flex-basis: 33%;
    }
  }

  .header {
    //flex-direction: column-reverse;
    padding: 1rem 0;

    .headline {
      font-size: 1.2rem;
    }

    .box {
      text-align: center;
      padding: 0 0 1rem 0;
    }

    .col-3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }

  // Pre Reveal -------------------------- //
  .main-title {
    $title-size: 2rem;
    font-size: $title-size;
    letter-spacing: $title-size/5;
    word-spacing: -8%;
    margin-bottom: 1.2rem;
  }

  .sub-title {
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.2rem;
    word-spacing: 0;
  }

  // .page-content {
  //     margin-top: -30%; //o
  // }

  .videoContainer {
    margin-top: -20%;
    padding: 0;

    .buttonCloseVideo {
      top: -$mobile-icon-size * 2;
      right: -0.5rem;

      img {
        width: $mobile-icon-size;
        display: inline-block;
      }
    }
  }

  .videoSelectorWrapper {
    margin-top: 0;
  }

  .box.col-12.videoSelector {


    .button {
      min-height: 4.5rem;
    }
  }

  .button {
    margin: 0.5rem 0;
  }

  .page-footer {
    .restartContainer {
      .box {
        //width: 20rem;
        display: flex;
        justify-content: center;

        .button {
          flex-grow: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .language-items {
    margin-top: -1px !important;
  }

  .header-home {
    .logo {
      height: 3.5rem;
      margin-top: 1rem;
    }
  }

  // Pre Reveal -------------------------- //
  .main-title {
    $title-size: 2rem;
    font-size: $title-size;
    letter-spacing: $title-size/5;
    word-spacing: -8%;
    margin-bottom: 1.2rem;
  }

  .sub-title {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    word-spacing: 0;
  }

  .audio-info {
    line-height: 1.5rem;

    img.audio_icon {
      width: 1.3rem;
      margin-left: 1rem;
      margin-bottom: -0.3rem;
    }
  }

  .reveal-date {
    font-size: 1.5rem;
    margin-top: 3rem;

    .date-sep {
      color: $color-primary;
      background-color: $color-primary;
      display: inline-block;
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      position: relative;
      top: 0.7rem;
    }
  }

  .box.col-4.start-button {
    flex-basis: 80%;

    .button {
      padding: 1rem;
    }
  }

  .videoContainer {
    margin-top: -30%;
    width: 100%;
    outline: none;
    padding: 0;

    overflow: visible;

    &.std {
      margin-top: 0;
    }

    .buttonCloseVideo {
      position: absolute;
      top: -$mobile-icon-size * 2;
      right: -0.5rem;
      z-index: 500;

      img {
        width: $mobile-icon-size;
        display: inline-block;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .header-home {
    .header-left {
      flex-basis: 38%;
    }

    .header-center {
      flex-basis: 23%;
    }

    .header-right {}
  }
}

@media only screen and (max-width: 320px) {}

// BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES
// BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES
// BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES
// BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES BACKGROUND MEDIA QUERIES
@media only screen and (min-aspect-ratio: 24/9) {
  .app-wrapper {
    background-size: 10%, 10%, 10%; //ok a 1920x1080
  }
}

@media only screen and (min-aspect-ratio: 21/9) and (max-aspect-ratio: 24/9) {
  .app-wrapper {
    background-size: 15%, 15%, 15%; //ok a 1920x1080
  }
}

@media only screen and (min-aspect-ratio: 16/9) and (max-aspect-ratio: 21/9) {
  .app-wrapper {
    background-size: 25%, 25%, 25%; //ok a 1920x1080
  }
}

@media only screen and (min-aspect-ratio: 10/9) and (max-aspect-ratio: 15/9) {
  .app-wrapper {
    background-size: 30%, 30%, 30%; //ok a 1920x1080
  }
}

// Per il mobile solo
@media only screen and (max-device-width: 420px) {
  .app-wrapper {
    background-size: 70%, 70%, 70%; //ok a 1920x1080
  }
}

@media only screen and (max-device-width: 380px) {
  .app-wrapper {
    background-size: 70%, 70%, 70%; //ok a 1920x1080
  }
}

// LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES
// LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES
// LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES
// LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES LANDSCAPE MEDIA QUERIES

@media (orientation: landscape) and (max-width: 900px) {

  html,
  body {
    font-size: 16px;
  }

  .page-header {
    margin-bottom: 0;
  }

  .page-content {
    margin-top: 0;
  }

  .header {
    height: $header-height/2;

    .header-logo-alt {
      max-width: 150px;
    }
  }

  .app-wrapper {
    background-size: 25%, 25%, 25%; //ok a 1920x1080
  }

  .video-background {
    top: 10rem;
    left: 0;
  }

  .container {
    &.full-height {
      width: 100%;
      min-width: 100%;
      height: initial;
      min-height: 100vh;
    }
  }

  .videoContainer {
    margin-top: 0%;
    padding: 0 22%;

    .buttonCloseVideo {
      top: -$mobile-icon-size;
      right: 18.5%;

      img {
        width: $mobile-icon-size;
        display: inline-block;
      }
    }
  }

  .box.col-4.start-button {
    flex-basis: 60%;
  }

  .button {
    min-height: 4.6rem;

    &.play {
      line-height: 2.3rem;
    }
  }

  .box.buttonsInfo {
    margin-top: 5.5rem;
    flex-basis: 90%;
    font-size: 0.8rem;
  }

  .box.col-12.videoSelector {
    flex-basis: 95%;
    padding-top: 0;

    .box.col-4 {
      flex-basis: 33%;

      &:nth-child(1) {
        padding-right: 0.25rem;
      }

      &:nth-child(2) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }

      &:nth-child(3) {
        padding-left: 0.25rem;
      }
    }

    .button {
      min-height: 0;
      margin: 0.5rem 0;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;

      .title {
        font-size: 0.6rem;
        line-height: 0.8rem;
        min-height: 2rem;
        padding: 0.2rem 0 0.2rem 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .author {
        font-size: 0.55rem;
        line-height: 0.5rem;
        padding: 0.2rem 0 0.4rem 0;
      }
    }
  }

  .box.col-12.videoNavigator {
    margin-top: 0;
    flex-basis: 95%;

    .box.col-4 {
      flex-basis: 33%;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .button {
      font-size: 0.6rem;
      min-height: 3.5rem;
      margin: 0.5rem 0;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }

  .restartContainer{
        .button {
          font-size: 0.6rem;
          min-height: 3.5rem;
          margin: 0.5rem 0;
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;
        }
  }
}

// @media only screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
// }

@media (orientation: landscape) and (max-width: 800px) {
  .videoContainer {
    margin-top: 0%;
    padding: 0 22%;

    .buttonCloseVideo {
      top: -$mobile-icon-size;
      right: 18.5%;

      img {
        width: $mobile-icon-size;
        display: inline-block;
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 700px) {
  .videoContainer {
    margin-top: 0%;
    padding: 0 12%;

    .buttonCloseVideo {
      top: -$mobile-icon-size;
      right: 7.3%;

      img {
        width: $mobile-icon-size;
        display: inline-block;
      }
    }
  }
}