*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// img {
//   width: auto;
//   max-width: 150%;
//   height: auto !important;
//   display: block;
// }

.flex-box,
.container {
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: row;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  flex-direction: row;


  &.column {
    flex-direction: column;
  }

  flex-wrap: wrap;
  justify-content: space-around;

  &.justify-left {
    justify-content: flex-start;
  }

  &.justify-right {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-evenly {
    justify-content: space-evenly;
  }

  &.stretch {
    align-items: stretch;
  }

  &.full-height {
    width: 100%;
    max-width: 100%;
    height: 100vh;
  }
}

@mixin transitionall($duration) {
  -webkit-transition: all $duration ease-in-out;
  -moz-transition: all $duration ease-in-out;
  -o-transition: all $duration ease-in-out;
  transition: all $duration ease-in-out;
}

@mixin transitionallcubic($duration) {
  -webkit-transition: all $duration cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all $duration cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all $duration cubic-bezier(0.77, 0, 0.175, 1);
  transition: all $duration cubic-bezier(0.77, 0, 0.175, 1);

}

.box {
  flex-grow: 0;
  padding: 1rem;
  position: relative;
  overflow: hidden;

  @include transitionall(0.2s);

  &.col-12,
  &.full {
    flex-basis: 100%;

  }

  &.col-10 {
    flex-basis: 83.334%;
  }

  &.col-9 {
    flex-basis: 74.99%;
  }

  &.col-8 {
    flex-basis: 66.66%;
  }

  &.col-6 {
    flex-basis: 50%;
  }

  &.col-4 {
    flex-basis: 33%;
  }

  &.col-3 {
    flex-basis: 25%;
  }

  &.col-2 {
    flex-basis: 16.666666%;
  }

  &.col-1_5 {
    flex-basis: 20%;
  }

  &.col-1 {
    flex-basis: 10%;
  }

  &.visible {
    overflow: visible;
  }

  .column & {
    padding: 0 1rem;

    & .nopadding {
      padding: 0;
    }
  }

  .nopadding & {
    padding: 0;
  }
}

.inline {
  display: inline;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.nopadding {
  padding: 0;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  height: calc(100vh);
}

.container {
  width: 100%;
  margin: 0 auto;
}

.wrapper:after {
  content: "";
  display: table;
  clear: both;
}

/* Da 30em in su */
@media only screen and (min-width: 640px) {
  .wrapper {
    width: 100%;
    //max-width: 80em;

    &.full {
      width: 100%;
      max-width: 100%;
    }

    .container {
      width: 90%;
      //max-width: 73.125em; //1170
      max-width: 90em; //1440

      &.wide {
        width: 100%;
        max-width: 90em; //1440
      }

      &.full {
        width: 100%;
        max-width: 100%;
      }
    }

    .container .container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  // .box {
  //   &.col-3 {
  //     flex-basis: 35%;
  //   }
  // }
}

@media only screen and (max-width: 780px) {
  .box {
    &.col-6 {
      flex-basis: 100%;
    }

    &.col-4 {
      flex-basis: 50%;
    }

    &.col-3 {
      flex-basis: 50%;
    }

    &.auto {
      flex-basis: 33%;
    }
  }
}

@media only screen and (max-width: 650px) {
  .box {
    &.col-6 {
      flex-basis: 100%;
    }

    &.col-4 {
      flex-basis: 50%;
    }

    &.col-3 {
      flex-basis: 50%;
    }

    &.auto {
      flex-basis: 33%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .container {
    &.full-height {
      width: 100%;
      min-width: 100%;
      height: initial;
      min-height: 100vh;
    }
  }

  .box {
    flex-basis: 100%;

    &.col-12,
    &.full {
      flex-basis: 100%;
    }

    &.col-10 {
      flex-basis: 100%;
    }

    &.col-9 {
      flex-basis: 100%;
    }

    &.col-8 {
      flex-basis: 100%;
    }

    &.col-6 {
      flex-basis: 100%;
    }

    &.col-4 {
      flex-basis: 100%;
    }

    &.col-3 {
      flex-basis: 100%;
    }

    &.col-2 {
      flex-basis: 100%;
    }

    &.col-1_5 {
      flex-basis: 100%;
    }
  }
}

@media only screen and (max-width: 320px) {
  .box {
    flex-basis: 100%;

    &.col-12,
    &.full {
      flex-basis: 100%;
    }

    &.col-10 {
      flex-basis: 100%;
    }

    &.col-9 {
      flex-basis: 100%;
    }

    &.col-8 {
      flex-basis: 100%;
    }

    &.col-6 {
      flex-basis: 100%;
    }

    &.col-4 {
      flex-basis: 100%;
    }

    &.col-3 {
      flex-basis: 100%;
    }

    &.col-2 {
      flex-basis: 100%;
    }

    &.col-1_5 {
      flex-basis: 100%;
    }
  }
}